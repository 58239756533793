import React, { useEffect } from "react";
import FirstWaveAi from "../../Components/FirstWaveAi";
import Notified from "../../Components/Notified";
import ColdCalling from "../../Components/ColdCalliing";
import { Box, Grid } from "@mui/material";
import ServicesHelp from "../../Components/ServicesHelp";
import GetStartedCard from "../../Components/GetStartedCard";
import ContactCard from "../../Components/ContactCard";
import HeaderWithTwoButtons from "../../Components/HeaderWithTwoButtons";
import BasicTasks from "../../Components/BasicTasks/idex";
import PricingCardWithDuration from "../../Components/PricingCardWithDuration";
import DashboardIcon from "../../asets/images/messageIcon.png"; // Update with actual service icon paths
import CampaignIcon from "../../asets/images/messageIcon.png";
import LanguageIcon from "../../asets/images/messageIcon.png";
import DataIntegrationIcon from "../../asets/images/messageIcon.png";
import AutomatedCallingIcon from "../../asets/images/messageIcon.png";
import LeadDataIcon from "../../asets/images/messageIcon.png";
import CRMIntegrationIcon from "../../asets/images/messageIcon.png";
import MetricsAnalyticsIcon from "../../asets/images/messageIcon.png";
import RealTimeMonitoringIcon from "../../asets/images/messageIcon.png";
import ScalabilityIcon from "../../asets/images/messageIcon.png";
import CustomizableScriptsIcon from "../../asets/images/messageIcon.png";
import UserPermissionsIcon from "../../asets/images/messageIcon.png";
import CustomerSupportIcon from "../../asets/images/messageIcon.png";
import ComplianceIcon from "../../asets/images/messageIcon.png";

function ProductCustomerSidekick() {
	const cardData = [
		{
			title: "Dashboard Access",
			description:
				"Access to real-time information for better decision-making and tracking.",
			icon: DashboardIcon,
		},
		{
			title: "Campaign Targeting",
			description:
				"Identify and engage specific target groups with customized campaigns.",
			icon: CampaignIcon,
		},
		{
			title: "Language Customization",
			description:
				"Communicate with clients in their preferred language for better engagement.",
			icon: LanguageIcon,
		},
		{
			title: "Data Integration",
			description:
				"Seamlessly integrate with your existing tools and systems for enhanced productivity.",
			icon: DataIntegrationIcon,
		},
		{
			title: "Automated Calling",
			description:
				"Let AI handle the tedious task of cold calling, freeing up time for more strategic work.",
			icon: AutomatedCallingIcon,
		},
		{
			title: "Lead Data Collection",
			description:
				"Gather and analyze leads' data for more effective marketing and sales strategies.",
			icon: LeadDataIcon,
		},
		{
			title: "CRM Integration",
			description:
				"Integrate with your existing CRM systems to streamline operations and improve customer management.",
			icon: CRMIntegrationIcon,
		},
		{
			title: "Metrics and Analytics",
			description:
				"Track key metrics and gain insights to optimize your sales and marketing efforts.",
			icon: MetricsAnalyticsIcon,
		},
		{
			title: "Real-Time Monitoring",
			description:
				"Monitor performance in real-time to make timely adjustments and improve results.",
			icon: RealTimeMonitoringIcon,
		},
		{
			title: "Scalability",
			description:
				"Easily scale your operations to meet the growing demands of your business.",
			icon: ScalabilityIcon,
		},
		{
			title: "Customizable Scripts",
			description:
				"Create and utilize custom scripts for more effective communication with clients.",
			icon: CustomizableScriptsIcon,
		},
		{
			title: "User Permissions",
			description:
				"Manage user access and permissions to ensure data security and integrity.",
			icon: UserPermissionsIcon,
		},
		{
			title: "Customer Support",
			description:
				"Get prompt assistance to resolve any issues and ensure smooth operations.",
			icon: CustomerSupportIcon,
		},
		{
			title: "Compliance",
			description:
				"Ensure all operations are compliant with relevant regulations and standards.",
			icon: ComplianceIcon,
		},
	];

	const headerData = {
		pageName: "Products",
		title: "Customer Sidekick",
		description:
			"Sales Hero is your ultimate AI-powered sales assistant, designed to optimize your sales process and increase conversions.",
		button1: "Chat with sales",
		button2: "View Pricing",
	};

	const plans = [
		{
			title: "Free",
			price: "$0",
			description: "Free Features.",
			duration: "per month",
			features: [
				"Secure Managed Environment",
				"Users forward their calls to Customer Sidekick",
				"Receive a set of pre-identified Q&As",
			],
		},
		{
			title: "Basic plan",
			price: "$200",
			description: "Basic features.",
			duration: "per month",
			features: [
				"Receive a set of pre-identified Q&As",
				"Basic customization for Q&A",
				"Monthly Performance Reports",
			],
		},
		{
			title: "Advanced plan",
			price: "$700",
			description: "Advanced features.",
			duration: "per month",
			features: [
				"More customization options for Q&A",
				"Enhanced support and features",
				"Integration with CRM System",
			],
		},
		{
			title: "Professional plan",
			price: "$1000",
			description: "Professional features.",
			duration: "per month",
			features: [
				"Full customization options for Q&A",
				"Premium support and features",
				"Caps around talk time",
			],
		},
	];

	const headings = {
		title: "Pricing",
		heading: "Plans that fit your scale",
		description:
			"Simple, transparent pricing that grows with you. Try any plan free for 30 days.",
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
			<HeaderWithTwoButtons headerData={headerData} />
			<BasicTasks />
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					mt: { md: "96px", xs: "64px" },
				}}
			>
				<Box sx={{ width: { md: "85%", xs: "95p%", sm: "90%" } }}>
					<ServicesHelp />
					<Grid container spacing={4}>
						{cardData.map((card, index) => (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<ContactCard card={card} />
							</Grid>
						))}
						<Grid item xs={12} sm={6} md={4}>
							<GetStartedCard />
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* <HowPurchase /> */}
			<PricingCardWithDuration plans={plans} headings={headings} />
			<ColdCalling />
			<Notified />
			<FirstWaveAi />
		</Box>
	);
}

export default ProductCustomerSidekick;
