export function checkValue(e, type) {
	const value = e.target.value;

	if (value.length === 0) {
		return {
			value,
			error: true,
		};
	}

	const isPasswordValid =
		/^(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/.test(
			value
		);
	const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
		value
	);

	if (type === "password" || type === "email") {
		return {
			value,
			error: type === "password" ? !isPasswordValid : !isEmailValid,
		};
	}

	return {
		value,
		error: false,
	};
}

export const validateAuth = (data) => {
  try {
    let error = null;
    const trimmedEmail = data.email.trim();
    const trimmedPassword = data.password.trim();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*~`:"'?,./]).{8,16}$/;

    

    switch (true) {
      case trimmedEmail.length === 0 && trimmedPassword.length === 0:
        error = 'Email and Password are required';
        return error;
      case trimmedEmail.length === 0:
        error = 'Email is required';
        return error;
      case trimmedEmail.length < 4:
        error = 'Email should have at least 4 characters';
        return error;
      case !emailRegex.test(trimmedEmail):
        error = 'Invalid email format';
        return error;
      case trimmedPassword.length === 0:
        error = 'Password is required';
        return error;
      case !passwordRegex.test(trimmedPassword):
        error = 'Password must be 8-16 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character (e.g., Qwerty@123).';
        return error;
      default:
        return error;
    }
  } catch (error) {
    return error.toString();
  }
};
