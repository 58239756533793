import { motion } from "framer-motion";
import TvIcon from "@mui/icons-material/Tv";
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

function CustomerJourneysSales({ journeysForSales }) {
    const { darkMode } = useContext(ThemeContext); 
    const [selectedOption, setSelectedOption] = useState(''); 
    const handleClick = (option) => { setSelectedOption(option); console.log("handleClick =>", option); };

    return (
        <Box sx={{ width: "95%", mt: "96px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            <Box sx={{ width: { md: "85%", xs: "95%", sm: "90%" } }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={5}>
                        <Box component={motion.div} initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }} transition={{ duration: 0.5 }} sx={{ display: "flex", flexDirection: "column", alignItems: { md: "flex-start", xs: "center" }, maxWidth: "400px" }}>
                            <Typography sx={{ fontSize: { md: "30px", xs: "24px" }, fontWeight: { md: "600", xs: "700" }, lineHeight: { xs: "36px", md: "36px" }, letterSpacing: "-0.25px", color: darkMode ? "#2F67CA" : "#101828" }}>
                                {journeysForSales.heading}
                            </Typography>
                            <Typography sx={{ mt: { md: "20px", xs: "16px" }, fontSize: { md: "18px", xs: "18px" }, fontWeight: "400", lineHeight: { md: "28px", xs: "24px" }, letterSpacing: "-0.25px", mb: "64px", color: darkMode ? "white" : "#475467" }}>
                                {journeysForSales.description}
                            </Typography>
                            {["Setting Up FAQs and Q&As", "Updating FAQs and Q&As", "Handling Unknown Questions", "Redirecting Calls to Human Agents", "Analyzing Call Performance", "Integrating Customer Sidekick with CRM", "Managing Multiple Locations"].map((option, index) => (
                                <Button key={index} component={motion.button} whileHover={{ scale: 1.05 }} sx={{ textTransform: "none", height: "60px", width: "100%", fontSize: "18px", fontWeight: "600", lineHeight: "28px", display: "flex", textAlign: "start", marginTop: "10px", backgroundColor: selectedOption === option ? "#1976d2" : "inherit", color: selectedOption === option ? "#FFFFFF" : "#475467", "&:hover": { backgroundColor: "#1976d2" }, justifyContent: "flex-start", marginLeft: "10px" }} onClick={() => handleClick(option)}>
                                    {option}
                                </Button>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={7} sx={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "700px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: darkMode ? "#041932" : "#E9F1FC", borderRadius: "8px", padding: { xs: "32px 0px" }, overflow: "hidden", flexWrap: "wrap", width: "100%", maxWidth: "80%", mt: 2, mb: 2 }}>
                            <Grid container spacing={2} sx={{ minHeight: "700px", display: "flex", flexWrap: "wrap", position: "relative" }}>
                                {selectedOption === "" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        {["Sign Up and Login", "Dashboard Navigation", "Budget Selection", "Language Customization", "A/B Testing Setup"].map((label, idx) => (
                                            <Box key={idx} sx={{ ml: { xs: "10px", sm: "30px", md: "50px" }, display: "flex", alignItems: "center", backgroundColor: "#FFFFFF", width: { sm: "100%", xs: "250px" }, textTransform: "none", borderRadius: "80px", marginBottom: "24px", boxShadow: 1, padding: "10px" }}>
                                                <IconButton sx={{ color: "#E3EFFD", m: { xs: "5px", sm: "16px", md: "16px" } }}>
                                                    {idx === 0 && <PeopleAltOutlinedIcon sx={{ color: "#7F56D9" }} />}
                                                    {idx === 1 && <TvIcon sx={{ color: "#C37E5E" }} />}
                                                    {idx === 2 && <RedeemOutlinedIcon sx={{ color: "#3D9060" }} />}
                                                    {idx === 3 && <RedeemOutlinedIcon sx={{ color: "#2F67CA" }} />}
                                                    {idx === 4 && <SettingsApplicationsIcon sx={{ color: "#D14949" }} />}
                                                </IconButton>
                                                <Typography sx={{ ml: "10px", fontSize: { xs: "14px", sm: "16px" }, fontWeight: { xs: "450", sm: "600" }, color: [ "#7F56D9", "#C37E5E", "#3D9060", "#2F67CA", "#D14949" ][idx] }}>
                                                    {label}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Grid>
                                )}
                                {selectedOption === "Updating FAQs and Q&As" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.1 }}>
                                            {["Sign Up and Login", "Dashboard Navigation", "Unknown Question Log", "Update FAQs"].map((label, idx) => (
                                                <Box key={idx} sx={{ ml: { xs: "10px", sm: "30px", md: "50px" }, display: "flex", alignItems: "center", backgroundColor: "#FFFFFF", width: { sm: "100%", xs: "250px" }, textTransform: "none", borderRadius: "80px", marginBottom: "24px", boxShadow: 1, padding: "10px" }}>
                                                    <IconButton sx={{ color: "#E3EFFD", m: { xs: "5px", sm: "16px", md: "16px" } }}>
                                                        {idx === 0 && <PeopleAltOutlinedIcon sx={{ color: "#7F56D9" }} />}
                                                        {idx === 1 && <TvIcon sx={{ color: "#C37E5E" }} />}
                                                        {idx === 2 && <RedeemOutlinedIcon sx={{ color: "#3D9060" }} />}
                                                        {idx === 3 && <RedeemOutlinedIcon sx={{ color: "#2F67CA" }} />}
                                                    </IconButton>
                                                    <Typography sx={{ ml: "10px", fontSize: { xs: "14px", sm: "16px" }, fontWeight: { xs: "450", sm: "600" }, color: [ "#7F56D9", "#C37E5E", "#3D9060", "#2F67CA" ][idx] }}>
                                                        {label}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </motion.div>
                                    </Grid>
                                )}

                                <Grid item xs={6} md={4} sm={5} sx={{ display: "flex", overflow: "hidden", flexWrap: "wrap", alignItems: "center", position: { md: "absolute", xs: "relative" }, right: { md: "-70px", xs: "-15%" }, bottom: { md: "-15%", xs: "-170px" }, width: "100%" }}>
                                    <Box sx={{ width: { xs: "200px", md: "350px" }, objectFit: "cover", display: "flex", flexWrap: "wrap", overflow: "hidden", justifyContent: "center", alignItems: "center" }}>
                                        <img src={journeysForSales.pic} alt="Robot" style={{ objectFit: "cover", minHeight: "300px", width: "100%", objectPosition: "bottom" }} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CustomerJourneysSales;
