import * as variables from '../constants/constants';

export const APIsRequest = {
  signupRequest: async (deviceId, data) => {
    const headers =  { 'User-Device': deviceId, 'Content-Type': 'application/json' };
    return await fetch(`${variables.AUTH_API}/signup`, { body: JSON.stringify(data), method: 'POST', headers });
  },

  signinRequest: async (deviceId, data) => {
    const headers =  { 'User-Device': deviceId, 'Content-Type': 'application/json' };
    return await fetch(`${variables.AUTH_API}/signin`, { body: JSON.stringify(data), method: 'POST', headers });
  }
};
