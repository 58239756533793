import React, { useContext } from "react";
import { Typography, Grid, Box, Button, } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import "../../asets/fonts/fonts.css";
import { ThemeContext } from "../../context/ThemeContext";

const faqs = [
  {
    icon: <HourglassEmptyIcon />,
    question: "Is there a free trial available?",
    answer:
      "Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
  },
  {
    icon: <SwapHorizIcon />,
    question: "Can I change my plan later?",
    answer:
      "Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
  },
  {
    icon: <DoDisturbAltIcon />,
    question: "What is your cancellation policy?",
    answer:
      "We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
  },
  {
    icon: <DescriptionIcon />,
    question: "Can other info be added to an invoice?",
    answer:
      "At the moment, the only way to add additional information to invoices is to add the information to the workspace's name.",
  },
  {
    icon: <CreditCardIcon />,
    question: "How does billing work?",
    answer:
      "Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
  },
  {
    icon: <MailOutlinedIcon />,
    question: "How do I change my account email?",
    answer:
      "You can change the email address associated with your account by going to untitled.com/account from a laptop or desktop.",
  },
];

const FaqsCard = ({ searchValue }) => {
  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchValue.toLowerCase())
  );

  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: {
          md: "96px",
          xs: "64px",
        },
      }}>
      <Box
        sx={{
          width: { md: "90%", xs: "90%", sm: "90%", xl: "85%", lg: "85%" },
        }}>
        <Grid container spacing={4}>
          {filteredFaqs.map((faq, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                maxWidth: "400px",
              }}
              textAlign={"center"}
              md={4}
              key={index}>
              <Button
                sx={{
                  width: "48px",
                  height: "48px",
                  color: darkMode ? "#bbb" : "#344054",
                  border: darkMode ? "solid 1px #444" : "solid 1px #EAECF0",
                  borderRadius: "8px",
                  textAlign: "center",
                  backgroundColor: darkMode ? "#1e1e1e" : "#fff",
                }}>
                {faq.icon}
              </Button>

              <Typography
                sx={{
                  styleName: "Text xl/Semibold",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "30px",
                  textAlign: "center",
                  mb: "8px",
                  mt: "20px",
                  color: darkMode ? "#fff" : "#101828",
                  fontFamily: "Inter",
                }}>
                {faq.question}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineH: "24px",
                  textAlign: "center",
                  color: darkMode ? "#bbb" : "#475467",
                }}>
                {faq.answer}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FaqsCard;
