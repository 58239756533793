import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import {
	ExpandMore,
	ExpandLess,
	Brightness4,
	Brightness7,
} from "@mui/icons-material";
import {
	// ArticleOutlined as BlogIcon,
	// SchoolOutlined as CaseStudiesIcon,
	//PlayCircleOutlineOutlined as VideoTutorialsIcon,
	// DescriptionOutlined as DocumentationIcon,
	HelpOutline as FAQsIcon,
} from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeContext } from "../../context/ThemeContext";
import "../../asets/fonts/fonts.css";

const pages = [
	{ name: "About Us", path: "/about-us" },
	{
		name: "Products",
		subPages: [
			// { name: "Product", path: "/Product" },
			{
				name: "Sales Hero",
				path: "/product-sales-hero",
				description: "The latest industry news, updates and info.",
			},
			{
				name: "Customer Sidekick",
				path: "/product-customer-sidekick",
				description: "Learn how our customers are making big changes.",
			},
		],
	},
	{
		name: "Resources",
		subPages: [
			// {
			//   name: "Blog",
			//   path: "/Blog",
			//   description: "The latest industry news, updates and info.",
			//   icon: <BlogIcon sx={{ color: "#1065C7" }} />,
			// },
			// {
			//   name: "Case Studies",
			//   path: "/case-studies",
			//   description: "Learn how our customers are making big changes.",
			//   icon: <CaseStudiesIcon sx={{ color: "#1065C7" }} />,
			// },
			// {
			//   name: "Video tutorials",
			//   path: "/tutorials&guides",
			//   description: "Get up and running on new features and techniques.",
			//   icon: <VideoTutorialsIcon sx={{ color: "#1065C7" }} />,
			// },
			// {
			//   name: "Documentation",
			//   path: "/Documentation",
			//   description: "All the boring stuff that you (hopefully) won’t need.",
			//   icon: <DocumentationIcon sx={{ color: "#1065C7" }} />,
			// },
			{
				name: "FAQ",
				path: "/faqs",
				description: "Learn, fix a problem, and get answers to your questions.",
				icon: <FAQsIcon sx={{ color: "#1065C7" }} />,
			},
		],
	},
	{
		name: "Industry",
		subPages: [
			{
				name: "Real Estate",
				path: "/industry-niche/Real Estate Industry",
				description: "Description For Real Estate Industry.",
			},
			{
				name: "Modern Healthcare Sector",
				path: "/industry-niche/Modern Healthcare Sector",
				description: "Description For Modern Healthcare Sector.",
			},
			{
				name: "Financial Services and Banking",
				path: "/industry-niche/Financial Services and Banking",
				description: "Description For Financial Services and Banking.",
			},
			{
				name: "Educational Technology",
				path: "/industry-niche/Educational Technology",
				description: "Description For Educational Technology.",
			},
		],
	},
	{ name: "Pricing", path: "/price" },
];

const ResponsiveAppBar = () => {
	const { darkMode, toggleDarkMode } = useContext(ThemeContext);
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElProducts, setAnchorElProducts] = useState(null);
	const [anchorElResources, setAnchorElResources] = useState(null);
	const [anchorElIndustry, setAnchorElIndustry] = useState(null);
	//const location = useLocation();
	// const navigate = useNavigate();

	// useEffect(() => {
	//     // This effect will run every time the route changes
	// }, [location]);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenSubMenu = (event, setAnchor) => {
		setAnchor(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
		setAnchorElProducts(null);
		setAnchorElResources(null);
		setAnchorElIndustry(null);
	};

	const theme = createTheme({
		palette: {
			mode: darkMode ? "dark" : "light",
			primary: {
				main: "#1065C7",
			},
			background: {
				default: darkMode ? "#121212" : "#ffffff",
			},
		},
		typography: {
			fontFamily: "Inter",
		},
	});

	const renderSubMenuItems = (subPages) =>
		subPages.map((subPage) => (
			<MenuItem
				key={subPage.name}
				onClick={handleCloseNavMenu}
				component={RouterLink}
				to={subPage.path}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					"&:hover": {
						backgroundColor: darkMode ? "#616161" : "#e0e0e0",
					},
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{subPage.icon && <Box sx={{ mr: 1 }}>{subPage.icon}</Box>}
					<Typography
						fontFamily="Inter"
						fontWeight={600}
						fontSize="16px"
						lineHeight="24px"
					>
						{subPage.name}
					</Typography>
				</Box>
				{subPage.description && (
					<Typography
						fontFamily="Inter"
						fontWeight={400}
						fontSize="14px"
						lineHeight="20px"
						color={darkMode ? "#bdbdbd" : "#757575"}
					>
						{subPage.description}
					</Typography>
				)}
			</MenuItem>
		));

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppBar
				sx={{
					color: darkMode ? "white" : "black",
					backgroundColor: darkMode ? "#333" : "white",
					display: "flex",
					alignItems: "center",
					marginBottom: "19px",
				}}
				position="fixed"
				elevation={0}
			>
				<Container maxWidth="xl">
					<Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								marginLeft: { xs: 2, md: 12 }, // Adjust marginLeft for mobile and desktop
							}}
						>
							<Link to="/" style={{ textDecoration: "none" }}>
								<Typography
									noWrap
									sx={{
										color: "black",
										fontFamily: "Inter",
										fontWeight: 600,
										fontSize: 22,
										lineHeight: "24px",
										padding: 0,
										display: { xs: "none", md: "flex" }, // Hide on mobile
									}}
								>
									<Box
										component="span"
										sx={{
											color: darkMode ? "whitesmoke" : "#1065C7",
										}}
									>
										First Wave AI
									</Box>
								</Typography>
							</Link>

							<Box
								sx={{
									display: { xs: "flex", md: "none" },
									ml: 2,
								}}
							>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenNavMenu}
									color="inherit"
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "left",
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: "block", md: "none" }, // Show menu on mobile
									}}
								>
									{pages.map((page) =>
										page.subPages ? (
											<div key={page.name}>
												<MenuItem
													onClick={(event) =>
														handleOpenSubMenu(
															event,
															page.name === "Products"
															? setAnchorElProducts
															: page.name === "Resources"
															? setAnchorElResources
															: setAnchorElIndustry
														)
													}
												>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<Typography
															fontFamily="Inter"
															fontWeight={600}
															textAlign="center"
															fontSize="16px"
															lineHeight="24px"
														>
															{page.name}
														</Typography>
														{	page.name === "Products"
															? ( anchorElProducts ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} /> ) )
															: page.name === "Resources"
															? ( anchorElResources ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} />) )
															: anchorElIndustry ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} /> )
														}
													</Box>
												</MenuItem>
												<Menu
													anchorEl={
														page.name === "Products"
														? anchorElProducts
														: page.name === "Resources"
														? anchorElResources
														: anchorElIndustry
													}
													open={Boolean(
														page.name === "Products"
														? anchorElProducts
														: page.name === "Resources"
														? anchorElResources
														: anchorElIndustry
													)}
													onClose={handleCloseNavMenu}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "left",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "left",
													}}
													PaperProps={{
														style: {
															backgroundColor: darkMode ? "#424242" : "#f5f5f5",
															color: darkMode ? "white" : "black",
															borderRadius: "8px",
															marginTop: "10px",
															minWidth: "250px",
														},
													}}
												>
													{renderSubMenuItems(page.subPages)}
												</Menu>
											</div>
										) : (
											<MenuItem
												key={page.name}
												onClick={handleCloseNavMenu}
												component={RouterLink}
												to={page.path}
											>
												<Typography
													fontFamily="Inter"
													fontWeight={600}
													textAlign="center"
													fontSize="16px"
													lineHeight="24px"
												>
													{page.name}
												</Typography>
											</MenuItem>
										)
									)}
								</Menu>
							</Box>
						</Box>

						<Box
							sx={{
								flexGrow: 1,
								display: {
									xs: "none",
									md: "flex",
								},
								justifyContent: "flex-start",
								ml: 8,
							}}
						>
							{pages.map((page) =>
								page.subPages ? (
									<Box
										key={page.name}
										sx={{
											display: "flex",
											alignItems: "center",
											position: "relative",
										}}
									>
										<Button
											onClick={(event) =>
												handleOpenSubMenu(
													event,
													page.name === "Products"
													? setAnchorElProducts
													: page.name === "Resources"
													? setAnchorElResources
													: setAnchorElIndustry
												)
											}
											sx={{
												textDecoration: "none",
												my: 2,
												color: darkMode ? "white" : "#101828",
												display: "flex",
												alignItems: "center",
												textTransform: "none",
												fontFamily: "Inter",
												fontWeight: 600,
												marginLeft: "20px",
												marginRight: "20px",
												fontSize: "16px",
												lineHeight: "24px",
												cursor: "pointer",
											}}
										>
											{page.name}{" "}
											{	page.name === "Products"
												? ( anchorElProducts ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} /> ) )
												: page.name === "Resources"
												? ( anchorElResources ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} />) )
												: anchorElIndustry ? ( <ExpandLess sx={{ ml: 1 }} /> ) : ( <ExpandMore sx={{ ml: 1 }} /> )
											}
										</Button>
										<Menu
											anchorEl={
												page.name === "Products"
												? anchorElProducts
												: page.name === "Resources"
												? anchorElResources
												: anchorElIndustry
											}
											open={Boolean(
												page.name === "Products"
												? anchorElProducts
												: page.name === "Resources"
												? anchorElResources
												: anchorElIndustry
											)}

											onClose={handleCloseNavMenu}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "left",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "left",
											}}
											PaperProps={{
												style: {
													backgroundColor: darkMode ? "#424242" : "#f5f5f5",
													color: darkMode ? "white" : "black",
													borderRadius: "8px",
													marginTop: "10px",
													minWidth: "250px",
												},
											}}
										>
											{renderSubMenuItems(page.subPages)}
										</Menu>
									</Box>
								) : (
									<Button
										key={page.name}
										component={RouterLink}
										to={page.path}
										sx={{
											textDecoration: "none",
											my: 2,
											color: darkMode ? "white" : "#101828",
											display: "block",
											textTransform: "none",
											fontFamily: "Inter",
											fontWeight: 600,
											marginLeft: "20px",
											marginRight: "20px",
											fontSize: "16px",
											lineHeight: "24px",
											cursor: "pointer",
										}}
									>
										{page.name}
									</Button>
								)
							)}
						</Box>

						<Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }} >
							
							<Box sx={{ display: "flex", alignItems: "center", marginRight: "10px" }} >
								<IconButton onClick={toggleDarkMode} color="inherit">
									{darkMode ? <Brightness7 /> : <Brightness4 />}
								</IconButton>
							</Box>

							<Box sx={{ display: "flex", alignItems: "center" }} >
								<Button
									component={RouterLink}
									to="/signin"
									sx={{
										textDecoration: "none",
										color: darkMode ? "white" : "#101828",
										textTransform: "none",
										fontFamily: "Inter",
										fontWeight: 600,
										marginRight: "10px",
										fontSize: "16px",
										lineHeight: "24px",
										cursor: "pointer",
									}}
								>
									Log in
								</Button>
								<Button
									component={RouterLink}
									to="/signup"
									sx={{
										borderRadius: 4,
										backgroundColor: "#1065C7",
										padding: "8px 12px",
										color: "white",
										fontFamily: "Inter",
										fontWeight: 600,
										textTransform: "none",
										fontSize: "16px",
										lineHeight: "24px",
										cursor: "pointer",
										display: "flex",
										alignItems: "center",
										transition: "background-color 0.3s",
										"&:hover": { backgroundColor: "#01315b" },
										"&:active": { backgroundColor: "#0a91ff" },
									}}
									>
									Sign up
								</Button>
							</Box>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	);
};

export default ResponsiveAppBar;
