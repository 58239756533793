/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Alert, Backdrop, Box, Button, CircularProgress, Container, Grid, Link, TextField, Typography } from "@mui/material";

import { decrypt } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Notified from "../../Components/Notified";
import * as variables from '../../constants/constants';
import FirstWaveAi from "../../Components/FirstWaveAi";
import GoogleIcon from "../../asets/images/google.png";
import { APIsRequest } from "../../services/APIsRequest";
import { ThemeContext } from "../../context/ThemeContext";
import { checkValue, validateAuth } from "../../helpers/validation";

const Signin = () => {
	const navigate = useNavigate();
	const { darkMode } = React.useContext(ThemeContext);

	const [severity, setSeverity] = React.useState('');
	const [alertText, setAlertText] = React.useState('');
	const [disabled, setDisabled] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [dataValues, setDataValues] = React.useState({ email: { value: "", error: false }, password: { value: "", error: false } });

	useEffect(() => { window.scrollTo(0, 0) }, []);

	const handleSignin = async (key) => {
		key.preventDefault();
		const decryptedDeviceId = await decrypt(localStorage.getItem('deviceId'));
		const data = { email: dataValues.email.value, password: dataValues.password.value, is_google: false };

		const error = validateAuth(data);
		if (error !== null) { setAlertText(error); setSeverity('error'); return setShowAlert(true); }

		setIsLoading(true);
		try {
			const response = await APIsRequest.signinRequest(decryptedDeviceId, data);
			const resJson = await response.json();

			if (!response.ok) {
				setAlertText(resJson.error);
				setSeverity('error');
				setShowAlert(true);
				setIsLoading(false);
				return;
			}

			setAlertText(resJson?.message);
			setSeverity('success');
			setIsLoading(false);
			setShowAlert(true);
			setDisabled(true);

			window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${resJson?.data?.session?.access_token}/?deviceId=${decryptedDeviceId}`;
		} catch (error) {
			setAlertText(error?.message || error?.error || 'An error occurred');
			setSeverity('error');
			setShowAlert(true);
			setIsLoading(false);
		}
	}

    const googleSignin = useGoogleLogin({
        onSuccess: async (response) => {
			try {
				setIsLoading(true);
				const decryptedDeviceId = await decrypt(localStorage.getItem('deviceId'));
				const googleResponse = await fetch(variables.GOOGLE_AUTH_URL, { method: 'GET', headers: { Authorization: `Bearer ${response.access_token}`, }, });

				if (!googleResponse.ok) {
					const errorText = await googleResponse.text();
					setAlertText(`Error: ${errorText}`);
					setSeverity('error');
					setShowAlert(true);
					setIsLoading(false);
					return;
				}

				const resData = await googleResponse.json();
				const data = { email: resData?.email, username: resData?.name, is_google: true, };

				try {
					const response = await APIsRequest.signinRequest(decryptedDeviceId, data);
					const resJson = await response.json();
		
					if (!response.ok) {
						setAlertText(resJson.error);
						setSeverity('error');
						setShowAlert(true);
						setIsLoading(false);
						return;
					}
		
					setAlertText(resJson?.message);
					setSeverity('success');
					setShowAlert(true);
					setIsLoading(false);

					window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${resJson?.data?.session?.access_token}/?deviceId=${decryptedDeviceId}`;
				} catch (error) {
					setAlertText(error?.message || error?.error || 'An error occurred');
					setSeverity('error');
					setShowAlert(true);
					setIsLoading(false);
				}
			} catch (error) {
				setAlertText(error?.message || error?.error || 'An error occurred');
				setSeverity('error');
				setShowAlert(true);
				setIsLoading(false);
			}
		},
		onError: (error) => {
			setAlertText(error?.message || error?.error || 'An error occurred');
			setSeverity('error');
			setShowAlert(true);
			setIsLoading(false);
    	},
    });

	return (
		<>
			<Container component="main" maxWidth="xs">
				<Box sx={{ marginTop: 14, display: "flex", flexDirection: "column", alignItems: "center" }}>
					{showAlert && ( <Alert severity={severity} onClose={() => setShowAlert(false)} > {alertText} </Alert> )}

					<Backdrop sx={{ color: darkMode ? "#303030" : "#f5f5f5", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} ><CircularProgress size={60} /> </Backdrop>
					<Typography component="h1" variant="h5" sx={{ fontWeight: "bold", textAlign: "center", mt: 8 }} > Log in to your account </Typography>
					<Typography component="h2" variant="body2" sx={{ mt: 1, textAlign: "center" }} > Welcome back! Please enter your details. </Typography>

					<Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSignin} >
						<TextField
							required
							fullWidth
							autoFocus
							id="email"
							name="email"
							label="Email"
							margin="normal"
							variant="outlined"
							autoComplete="email"
							error={dataValues.email.error}
							value={dataValues.email.value}
							onChange={(e) => setDataValues({ ...dataValues, email: checkValue(e, "email") })}
						/>

						<TextField
							required
							fullWidth
							id="password"
							margin="normal"
							name="password"
							type="password"
							label="Password"
							variant="outlined"
							autoComplete="current-password"
							error={dataValues.password.error}
							value={dataValues.password.value}
							onChange={(e) => setDataValues({ ...dataValues, password: checkValue(e, "password") })}
						/>

						<Button disabled={disabled} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#1976d2", color: "#fff" }} > Sign in </Button>
						<Button fullWidth variant="outlined" sx={{ mt: 1, mb: 2, backgroundColor: "#fff", color: "#348ee7" }} onClick={googleSignin} >
							<img src={GoogleIcon} alt="google" style={{ width: '20px', height: '20px', marginRight: '10px' }} /> Sign in with Google
						</Button>

						<Grid container justifyContent="center"><Grid item> <Link sx={{ cursor: "pointer" }} onClick={() => navigate("/signup")} variant="body2" > {"Don't have an account ? Sign up"} </Link> </Grid> </Grid>
					</Box>
				</Box>
			</Container>
			<Notified />
			<FirstWaveAi />
		</>
	);
};

export default () => (<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> <Signin /> </GoogleOAuthProvider>);
