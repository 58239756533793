import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Home from "./Pages/Home";
import "./asets/fonts/fonts.css";
import AboutUs from "./Pages/AboutUs";
import Pricing from "./Pages/Pricing";
import Signin from "./Pages/Signin";
import SignUp from "./Pages/Signup";
import FaqsPage from "./Pages/FaqsPage";
import Footer from "./Components/Footer";
import { Product } from "./Pages/Product";
import ContactUs from "./Pages/ContactUs";
import { verifyDeviceId } from "./helpers";
import IndustryNiche from "./Pages/IndustryNiche";
import ResponsiveAppBar from "./Components/Navbar";
import ProductSalesHero from "./Pages/ProductSalesHero";
import ProductCustomerSidekick from "./Pages/ProductCustomerSidekick";

const Layout = ({ children }) => {
	const location = useLocation();
	React.useEffect(() => { verifyDeviceId() }, [location]);

	return (
		<div>
			<ResponsiveAppBar />
			{children}
			<Footer />
		</div>
	);
};

const App = () => {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/price" element={<Pricing />} />
					<Route path="/faqs" element={<FaqsPage />} />
					<Route path="/signin" element={<Signin />} />
					<Route path="/signup" element={<SignUp />} />
					<Route path="/Product" element={<Product />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/product-sales-hero" element={<ProductSalesHero />} />
					<Route path="/industry-niche/:article_header" element={<IndustryNiche />} />
					<Route path="/product-customer-sidekick" element={<ProductCustomerSidekick />} />
				</Routes>
			</Layout>
		</Router>
	);
}

export default App;
