export const encrypt = (data) => {
    try {
      return btoa(data);
    } catch (error) {
      window.location.replace("/");
      return error.toString();
    }
};
  
export  const decrypt = (data) => {
    try {
      return atob(data);
    } catch (error) {
      window.location.replace("/");
      return error.toString();
    }
};

export const verifyDeviceId = () => {
  const deviceId = localStorage.getItem('deviceId');
  if (deviceId !== null && deviceId !== undefined) return deviceId;

  const encryptDeviceId = encrypt(Math.random().toString(36).substr(2, 16));
  localStorage.setItem('deviceId', encryptDeviceId);
}
